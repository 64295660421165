import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <a href="https://www.linkedin.com/in/onurzorluer/">Onur Zorluer</a>
        </p>
      </header>
    </div>
  );
}

export default App;
